import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import AdminPlaceholder from 'components/consumer/AdminPlaceholder';
import isNewConsumerApp from 'commons/isNewConsumerApp';
import JavaScriptEmbed from './JavaScriptEmbed';
import BrowserOnly from '../BrowserOnly';

const NewAppBrowserOnly = isNewConsumerApp ? BrowserOnly : Fragment;

const HtmlEmbed = ({ config: { embedCode }, isAdmin }) => {
  if (isAdmin) {
    if (embedCode?.html?.length) {
      return (
        <AdminPlaceholder>
          <div dangerouslySetInnerHTML={{ __html: embedCode?.html }} />
        </AdminPlaceholder>
      );
    }
    return (
      <AdminPlaceholder>
        “HTML Embed” component that will render html
      </AdminPlaceholder>
    );
  }
  return (
    <NewAppBrowserOnly>
      <>
        {!!embedCode?.html && (
          <div
            className="sub-heading"
            dangerouslySetInnerHTML={{ __html: embedCode?.html }}
          />
        )}

        {!!embedCode?.js && <JavaScriptEmbed js={embedCode.js} />}
      </>
    </NewAppBrowserOnly>
  );
};

HtmlEmbed.propTypes = {
  config: PropTypes.object,
  isAdmin: PropTypes.bool,
};

export default HtmlEmbed;
